/* eslint-disable import/prefer-default-export */
import globalConfig from '../utils/globalConfig';
import getAppDir from 'src/utils/getAppDir';

export const THEMES = {
  LIGHT: 'LIGHT',
  ONE_DARK: 'ONE_DARK',
  UNICORN: 'UNICORN'
};

export const APP_DIR = getAppDir(__dirname, '../..');

export const API_URI = globalConfig('apiUri');

export const DEFAULT_URI = globalConfig('defaultClientUri');

export const API_URI_INNER = globalConfig('apiUriInner');

export const WEBSOCKET_URI = globalConfig('websocketUri');

export const DEFAULT_CLIENT_URI = globalConfig('defaultClientUri');

export const MAX_POST_SIZE = 300 * 1024 * 1024;

export const DAYS = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

export const SHORT_DAYS = ['sund', 'mon', 'tue', 'wed', 'thu', 'fri', 'satu'];

export const MONTHS = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'];

export const IS_SERVER = typeof window == 'undefined';

export const IS_PRODUCTION = process.env.NODE_ENV === 'production';

export const LANDING_LAST_SEARCH_LOCATIONS_COUNT = 8;

export const CITIES_LIMIT_FOR_WARNINGS_SUBSCRIPTION = 10;

export const WORLD_MAX_TEMP = 56.7;

export const WORLD_MIN_TEMP = -93.2;

export const WEATHER_WARNINGS = [
  { name: 'Gusts of wind', codes: { '1001': 'o', '1002': 'r' } },
  { name: 'Storm', codes: { '1003': 'y', '1004': 'o', '1005': 'r' } },
  { name: 'Blizzard', codes: { '1008': 'r' } },
  { name: 'Very heavy precipitation: rain', codes: { '1011': 'r' } },
  { name: 'Very heavy precipitation: snow', codes: { '1014': 'r' } },
  { name: 'Heavy rain', codes: { '1017': 'r' } },
  { name: 'Prolonged precipitation: rain', codes: { '1020': 'r', '1023': 'r' } },
  { name: 'Thunder and lightning', codes: { '1024': 'y', '1025': 'o' } },
  { name: 'Extremely low temperature', codes: { '1029': 'r' } },
  { name: 'Period of intense heat', codes: { '1032': 'r' } },
  { name: 'Abnormally cold weather', codes: { '1033': 'y' } },
  { name: 'Abnormally hot weather', codes: { '1036': 'y' } },
  { name: 'Freezing', codes: { '1039': 'y' } }
];

export const DIMENSION_UNITS = {
  temperature: [{ value: 'c', label: '°С', default: true }, { value: 'f', label: '°F' }],
  windSpeed: [
    { value: 'km/h', label: 'km/h', default: true }, { value: 'm/s', label: 'm/s' },
    { value: 'm/h', label: 'miles/h' }, { value: 'kn', label: 'Knots' }
  ],
  waveHeight: [{ value: 'sm', label: 'sm', default: true }, { value: 'feet', label: 'feet' }],
  precipitation: [{ value: 'mm', label: 'mm', default: true }, { value: 'sm', label: 'sm' }, { value: 'inch', label: 'inch' }],
  getDefault: function(p) {
    for (let i = 0; i < this[p].length; i++) if (this[p][i].default) return this[p][i].value;
    return null;
  },
  check: function(p, v) {
    for (let i = 0; i < this[p].length; i++) if (this[p][i].value === v) return true;
    return false;
  },
  find: function(p, v) {
    for (let i = 0; i < this[p].length; i++) if (this[p][i].value === v) return this[p][i];
    return false;
  }
};

export const WIDGET_TYPES = {
  city: ['City', 'Widget for city'],
  cities: ['Cities', 'Widget for cities'],
  toolbar: ['toolbar', 'Toolbar'],
  beaches: ['beaches', 'Beaches'],
  news: ['news', 'News']
};

export const UV_DESCRIPTION = {
  '0-2': 'low',
  '3-5': 'average',
  '6-7': 'high',
  '8-10': 'very high',
  '11-100': 'extreme',
};

export const WIND_DESCRIPTION = {
  '0-0.2': 'calm',
  '0.3-1.5': 'quiet wind',
  '1.6-3.3': 'light breeze',
  '3.4-5.4': 'light wind',
  '5.5-7.9': 'moderate wind',
  '8.0-10.7': 'fresh breeze',
  '10.8-13.8': 'strong wind',
  '13.9-17.1': 'strong wind',
  '17.2-20.7': 'very strong wind',
  '20.8-24.4': 'storm',
  '24.5-28.4': 'heavy storm',
  '28.5-32.6': 'violent storm',
  '32.6-100': 'hurricane',
};

export const TEMPERATURE_DESCRIPTION = {
  1: 'low temperature',
  2: 'comfortable temperature',
  3: 'elevated temperature',
  4: 'sharp drop in temperature',
  5: 'temperature drop',
  6: 'temperature increase',
  7: 'sharp rise in temperature',
  8: 'temperature unchanged',
};

export const CLOUD_DESCRIPTION = {
  1: 'clear',
  2: 'partly cloudy',
  3: 'cloudy',
  4: 'mainly cloudy',
};

export const PRESSURE_DESCRIPTION = {
  1: 'sharp decline',
  2: 'slight drop',
  3: 'without changes',
  4: 'slight increase',
  5: 'sharp rise',
};

export const THUNDERSTORM_POSSIBILITY = {
  1: 'no thunderstorms are foreseen',
  2: 'slight chance of a thunderstorm',
  3: 'thunderstorms are possible',
  4: 'thunderstorms',
};

export const PRECIPITATION_POSSIBILITY = {
  0: 'without precipitation',
  1: 'slight chance of light rain',
  2: 'light rain possible',
  3: 'light rain',
  4: 'little chance of rain',
  5: 'chance of rain',
  6: 'rain',
  7: 'low chance of showers',
  8: 'showers possible',
  9: 'torrential showers',
};

export const MOON_PHASES = {
  '0': 'New moon',
  '10-80': 'Waxing сrescent',
  '90': 'First quarter',
  '100-170': 'Waxing gibbous',
  '180': 'Full moon',
  '190-260': 'Waning gibbous',
  '270': 'Last quarter',
  '280-350': 'Waning crescent',
  '360': 'New moon',
};

export const WORLD_PARTS = [
  {
    worldPart: 'europe',
    title: 'Europe',
    page: '5ee36202c2b241648cc6547c',
    subregions: [
      { title: 'Western Europe' },
      { title : 'Northern Europe' },
      { title: 'Southern Europe' },
      { title: 'Eastern Europe' },
    ]
  },
  {
    worldPart: 'america',
    title: 'America',
    page: '5ee36202c2b241648cc6547d',
    subregions: [
      { title: 'North America' },
      { title: 'Central America' },
      { title: 'Caribbean' },
      { title: 'South America' },
    ],
  },
  {
    worldPart: 'cis',
    title: 'CIS',
    page: '5ee36202c2b241648cc6547b',
    subregions: [
      { title: 'CIS' }
    ],
  },
  {
    worldPart: 'asia',
    title: 'Asia',
    page: '5ee36202c2b241648cc6547e',
    subregions: [
      { title: 'Western asia' },
      { title: 'Central Asia' },
      { title: 'South Asia' },
      { title: 'North asia' },
      { title: 'East Asia' },
      { title: 'Southeast Asia' },
    ],
  },
  {
    worldPart: 'africa',
    title: 'Africa',
    page: '5ee36202c2b241648cc6547f',
    subregions: [
      { title: 'North Africa' },
      { title: 'West Africa' },
      { title: 'Central Africa' },
      { title: 'East africa' },
      { title: 'South Africa' },
    ],
  },
  {
    worldPart: 'australia_newzealand',
    title: 'Australia',
    page: '5ee36202c2b241648cc65480',
    subregions: [
      { title: 'Australia and New Zealand' },
      { title: 'Melanesia' },
      { title: 'Micronesia' },
      { title: 'Polynesia' },
    ],
  },
];

export const WEATHER_ICONS = [
  'd000', 'd100', 'd200', 'd210', 'd211', 'd212', 'd220', 'd221', 'd222', 'd240', 'd300', 'd310', 'd311', 'd312', 'd320', 'd321', 'd322', 'd340',
  'd400', 'd410', 'd411', 'd412', 'd420', 'd421', 'd422', 'd430', 'd431', 'd432', 'd440', 'd500', 'd600', 'n000', 'n100', 'n200', 'n210', 'n211',
  'n212', 'n220', 'n221', 'n222', 'n240', 'n300', 'n310', 'n311', 'n312', 'n320', 'n321', 'n322', 'n340', 'n400', 'n410', 'n411', 'n412', 'n420',
  'n421', 'n422', 'n430', 'n431', 'n432', 'n440', 'n500', 'n600'
];

export const SEA_STATUSES = {
  1: 'The sea is calm',
  2: 'The sea is comfortable',
  3: 'Back waves',
  4: 'A storm',
};
