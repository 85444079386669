import React from 'react';
import Helper from 'src/front/helpers/Helper';

function Logo(props) {
  return (
    <img
      alt="Logo"
      src={Helper.getFileUrl('logotype', 'dashboard.svg')}
      {...props}
    />
  );
}

export default Logo;
